$height: 40px;
@font-face {
  font-family: "Font Awesome baserCMS Toolbar";
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url("../../fonts/admin/fa-solid-900.eot");
  src: url("../../fonts/admin/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("../../fonts/admin/fa-solid-900.woff2") format("woff2"), url("../../fonts/admin/fa-solid-900.woff") format("woff"), url("../../fonts/admin/fa-solid-900.ttf") format("truetype"), url("../../fonts/admin/fa-solid-900.svg#fontawesome") format("svg");
}
body {
  margin-top: 40px !important;
}
.bca-toolbar {
  line-height: 1.15!important;
  font-family: 'Font Awesome baserCMS Toolbar', Arial, Geneva, sans-serif, "メイリオ", Verdana, "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "ＭＳ Ｐゴシック"!important;
  font-size: 12px!important;
  height: $height!important;
  position: fixed!important;
  z-index: 150!important;
  width: 100%!important;
  top: 0!important;
  left: 0!important;
  &,
  * {
    box-sizing: border-box!important;
    font-size:12px;
  }

  &__body {
    height: $height!important;
    background-color: $color_toolbar!important;
    display: flex!important;
    justify-content: space-between!important;
  }

  & &__logo {
    flex: 0 1 auto!important;
    display: flex!important;
    padding: 0 12px 0 12px!important;

    &-link {
      display: flex!important;
      justify-content: flex-start!important;
      align-items: center!important;
      color: currentColor!important;
      text-decoration: none!important;
    }

    &-symbol {
      padding: 4px 7px 4px 4px!important;
      margin-right: 8px!important;
      border-right: 1px solid #ccc!important;
      box-sizing: content-box!important;
      width: 24px;
      height: 21px;
    }

    &-text {
      display: inline!important;
      padding: 0!important;
      color: $color_toolbar_text!important;
      vertical-align: top!important;
    }
  }

  &__tools {
    flex: 1 0 auto!important;
    display: flex!important;
    justify-content: flex-start!important;
    align-items: center!important;

    &-button {
      a {
        &::before {
          margin: 0 .4em 0 .2em!important;
          display: inline-block!important;
          background-repeat: no-repeat;
        }
        margin: 8px 0.3em!important;
        padding: 3px 8px 3px 5px!important;
        background: #fff!important;
        border-radius: 22px!important;
        color: #333;
        text-decoration: none!important;

        &:hover {
          background: #ccc!important;
          color: #333!important;
        }
      }
      &-add {
        a {
          &::before {
            content: "\f055";
          }
        }
      }
      &-edit {
        a {
          &::before {
            content: "\f303";
          }
        }
      }
      &-publish {
        a {
          &::before {
            content: "\f06e";
          }
        }
      }
    }

    &-mode {
      span {
        margin: 8px!important;
        padding: 3px 8px 3px 5px!important;
        border-radius: 22px!important;
        background-color: $color_danger!important;
        font-weight: bold!important;
        color: $color_toolbar_text!important;
      }
    }
  }

  &__users {
    .bc-btn {
      margin-left:5px;
    }
    > ul {
      display: flex!important;
      margin: 0!important;
      padding: 0!important;
      height: 100%!important;

      > li {
        list-style: none!important;
        display: block!important;
        height: 100%!important;

        > a {
          display: flex!important;
          justify-content: center!important;
          align-items: center!important;
          height: 100%!important;
          position: relative!important;
          z-index: 1;
          box-shadow: 0 0 0 #ccc!important;
          border: 0!important;
          width: 100%!important;
          padding: 2px 20px!important;
          color: $color_toolbar_text!important;
          text-decoration: none!important;

          &:hover,
          &:focus {
            background-color: #444!important;
          }
        }

        > ul {
          display: none!important;
        }
      }

      > li.active {
        position: relative!important;

        > a {
          color: #ccc!important;
          background-color: $color_toolbar_text!important;
          border-bottom: 0!important;
        }

        > ul {
          display: block!important;
          margin: -1px 0 0!important;
          padding: 0;
          position: absolute!important;
          right: 0!important;
          border: 1px solid #ccc !important;
          box-shadow: 0 1px 3px #ccc!important;
          background-color: $color_toolbar_text!important;

          li {
            list-style: none!important;
            display: block!important;
            white-space: nowrap!important;
          }

          a {
            box-shadow: 0 0 0 #ccc!important;
            color: #535a62!important;
            border: 0!important;
            width: 100%!important;
            display: flex!important;
            justify-content: flex-start!important;
            align-items: center!important;
            padding: 2px 20px!important;
            text-decoration: none!important;
            height: 40px!important;
            position: relative!important;
            z-index: 1!important;

            &:hover {
              background-color: $color_background_menu_current_primary!important;
              color: $color_toolbar_text!important;
            }
          }
        }
      }
    }
  }
}
