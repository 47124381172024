.bca-select {
  &__select {
    appearance: none;
    background: url(../../img/admin/icn_select.svg) no-repeat center right 0.5em / 10px auto, #FFFFFF;
    font-size: $button_font_size_medium;
    padding: $button_padding_top 2.2em $button_padding_bottom 1.2em;
    border: 1px solid $color_control_border_color;
    line-height: 1.2;
    outline: none;
    border-radius: 3px;
    color:$color_text_secondary;
    margin:4px;
    &[data-bca-select-size=lg] {
      font-size: $button_font_size_large;
    }
    &[data-bca-select-size=sm] {
      font-size: $button_font_size_small;
    }
    &:focus {
      box-shadow: $focus_shadow;
      border-color: $color_primary;
      border-width: 1px;
    }
  }
}