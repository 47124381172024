/* BannerArea
----------------------------------------------- */
.bca-banners {
  &__ul {
    padding: 0;
  }
  &__li {
    margin-bottom: 2px;
    padding-left: 1px;
    padding-right: 1px;
    list-style: none;
  }
}