.bca-nav {
  font-size: 1.4rem;
  flex-basis: 208px;
  background: $color_background_menu;
  position: relative;
  z-index: 100;

  &__main {
    font-size: 1em;
    position: relative;
    //background: $color_background_primary;
    background: $color_background_menu;
    &-title {
      display: none;
    }
  }

  &__sub {
    font-size: 1em;
    position: relative;
    // border-top: 1px solid $color_border;
    background: $color_background_menu;
    &,
    *,
    *::before,
    *::after {
      box-sizing: border-box;
    }
    .bca-nav__main > &:first-of-type {
      border-top: none;
    }
    &-title {
      margin-top: 0;
      margin-bottom: 0;
      font-size: 1em;
      font-weight: bold;
      cursor: pointer;
      &-label {
        display: flex;
        vertical-align: middle;
        padding: 12px 10px 12px 10px;
        font-weight: 900;
        &:before {
          @extend %bca-icon;
          display: inline-block;
          width: 16px;
          text-align: center;
          margin: 0 10px 0 0 !important;
        }
        &:link,
        &:visited {
          // a:link のデフォルト設定を消すまで有効
          color: $color_menu_link;
        }
        &:hover {
          text-decoration: none;
          color: $color_darkest;
          background: $color_background_menu_dark;
        }
        span {
          font-weight: bold;
        }
      }
    }
    &[data-content-is-current="true"] {
      .bca-nav__sub-title {
        background-color: $color_secondary;
        &-label {
          color:$color_lightest;
          &:hover {
            //color:$color_darkest;
          }
        }
      }
    }

    &-list {
      display: none;
      position: absolute;
      top: 0;
      left: 100%;
      min-width: 200px;
      margin: 0;
      list-style: none;
      //background: $color_background_primary;
      //transform: translate(0, -50%);
      padding-left: 0;
      padding-top:11px;
      padding-bottom:11px;
      &-item {
        font-size: 1.4rem;
        display: block;
        position: relative;
        a {
          display: block;
          white-space: nowrap;
          // padding: 10px 20px;
          padding: 7px 20px 5px 35px;
          color: $color_menu_link;
          font-size: 1.3rem;
          // @extend .bca-icon--menuitem;
          &::before {
            font-size: 1.5em !important;
            opacity: 0.7;
          }
          &:link,
          &:visited {
            // a:link のデフォルト設定を消すまで有効
            color: $color_menu_link;
            &:after {
              position: absolute;
              top: 50%;
              right: 0;
              transform: translateY(-50%);
              content: "";
              display: block;
              width: 0;
              border-top: 10px solid transparent;
              border-bottom: 10px solid transparent;
            }
          }
          &:hover {
            font-weight: bold;
            text-decoration: none;
            color: $color_menu_hover;
            //background: lighten($color_border, 10%);
            &::before {
              opacity: 1;
            }
          }
        }
        &-title {
          // margin-left: 4px;
        }
      }
    }

    // サブメニューを表示
    &:hover &-list {
      display: block;
      background: $color_background_menu;
      box-shadow: 0 3px 5px rgba(#000, 0.2);
      z-index: 10;
      &::before {
        content: "";
        display: block;
        position: absolute;
        z-index: 20;
        top: 10px;
        left: -20px;
        border: 10px solid;
        border-color: transparent $color_background_menu transparent transparent;
      }
    }

    // 現在選択中のメニューでサブメニューがない場合
    &[data-content-is-current="true"] {
      .bca-nav__sub-title {
        background-color: $color_background_menu_current;
        &-label {
          color:$color_menu_current;
          &:hover {
            background: $color_background_menu_current;
          }
        }
      }
    }

    // 中が展開されている時
    &[data-content-is-expanded="true"] {
      .bca-nav__sub-title {
        color: $color_menu_link;
        cursor: default;
        background: $color_background_menu_current_parent;
        &-label {
          //color: #fff;
        }
      }
      .bca-nav__sub-list {
        display: block;
        position: relative;
        top: auto;
        left: auto;
        width: auto;
        transform: none;
        box-shadow: none;
        background: $color_background_menu_current_submenu;
        padding: 0;
        &-item {
          a {
            padding: 6px 20px 5px 35px;
            white-space: inherit;
          }
          // 現在開いているページ
          &[data-sub-content-is-current="true"] {
            // background: $color_primary;
            background: $color_background_menu_current;
            a {
              padding: 13px 20px 11px 35px;
              position: relative;
              color: $color_menu_current;
              font-weight: bold;
              &:link,
              &:visited {
                color: $color_menu_current;
              }
            }
          }
        }
      }
      &:hover {
        .bca-nav__sub-list {
          &::before {
            content: none;
          }
        }
      }
    }

    // アイコン幅調整
    &[data-content-type] {
      .bca-nav__sub-title > a:before {
        width: 16px;
        text-align: center;
        margin-right: 10px;
      }
    }

    &[data-content-type="dashboard"] {
      .bca-nav__sub-title > a {
        @include bca-icon(dashboard);
      }
    }

    &[data-content-type="contents"] {
      .bca-nav__sub-title > a {
        @include bca-icon(contents);
      }
    }

    &[data-content-type="system"] {
      .bca-nav__sub-title > a {
        @include bca-icon(system);
      }
    }
  }


  // 設定メニュー
  &__system {
    border-top: 1px solid $color_menu_border;
    border-bottom: 1px solid $color_menu_border;
    [data-bca-state="true"] &-title-icon {
        transform: rotate(180deg);
    }
    &-title {
      margin: 0;
      button {
        @include bca-icon(system);
        appearance: none;
        text-align: left;
        font-size: 1.4rem;
        font-weight: bold;
        border: none;
        background: none;
        margin: 0;
        padding: 12px 10px 12px 10px;
        width: 100%;
        height: 100%;
        color: $color_menu_link;
        cursor: pointer;
        outline: none;
      }

      // 開閉アイコン
      &-icon{
        display: inline-block;
        margin: 0;
        transition: 0.1s all;
        vertical-align: middle;
        margin-right: 6px;
        margin-left:6px;
        float: right;
        &::before {
        	margin:0;
        }
      }
    }

    &-list {
      &-item {
        padding: 0;
        position: relative;

        // 閉じている時のメニューhover
        &:hover {
          color: $color_primary;

          // サブメニューを表示
          .bca-nav__system-sub-list {
            display: block;
            background: $color_background_menu;
            box-shadow: 0 3px 5px rgba(#000, 0.2);
            z-index: 10;

            &::before {
              content: "";
              display: block;
              position: absolute;
              z-index: 20;
              top: 10px;
              left: -20px;
              border: 10px solid;
              border-color: transparent $color_background_menu transparent transparent;
            }
          }
        }

        &-title {
          margin: 0;
          padding: 0;
          &-label {
            display: flex;
            vertical-align: middle;
            padding: 12px 10px 12px 10px;
            &:before {
              @extend %bca-icon;
              display: inline-block;
              width: 16px;
              text-align: center;
              margin-right: 10px !important;
            }
            &:link,
            &:visited {
              // a:link のデフォルト設定を消すまで有効
              color: $color_menu_link;
            }
            &:hover {
              text-decoration: none;
              color: $color_menu_link;
              background: $color_background_menu_hover;
            }
          }
        }
        // 現在選択中の時
        &[data-sub-content-is-current="true"] {
          .bca-nav__system-list-item-title {
            background-color: $color_background_menu_current;
            &-label {
              color:$color_menu_current;
              &:hover {
                background-color: $color_background_menu_current;
              }
            }
          }
        }
      }
    }

    // サブメニュー
    &-sub-list {
      display: none;
      margin: 0;
      list-style: none;
      position: absolute;
      top: 0;
      left: 100%;
      min-width: 200px;
      padding-left: 0;
      padding-top:11px;
      padding-bottom:11px;
      &-item {
        margin: 0;
        a {
          font-size: 1.3rem;
          padding: 7px 20px 5px 35px;
          color: $color_menu_link;
          display:block;
          white-space: nowrap;
        }
        a:hover {
          font-weight: bold;
          text-decoration: none;
        }
      }
    }

    &-list {
      &-item {
        // 中が展開されている時
        &[data-sub-content-is-expanded="true"] {
          .bca-nav__system-list-item {
            &-title {
              background-color: $color_background_menu_current_parent;
              &-label {
                //color:$color_menu_current;
              }
            }
          }
          .bca-nav__system-title {
            color: $color_menu_link;
            background: $color_background_menu_current;
            cursor: default;
          }
          .bca-nav__system-sub-list {
            display: block;
            position: relative;
            top: auto;
            left: auto;
            width: auto;
            transform: none;
            box-shadow: none;
            background: $color_background_menu_current_submenu;
            padding: 0;
            color: $color_menu_link;

            &-item {
              a {
                padding: 6px 20px 5px 35px;
                white-space: inherit;
              }
              // 現在開いているページ
              &[data-sub-item-is-current="true"] {
                // background: $color_primary;
                background: $color_background_menu_current;
                a {
                  padding: 13px 20px 11px 35px;
                  position: relative;
                  color: $color_menu_current;
                  font-weight: bold;
                }
              }
            }
          }

          &:hover {
            .bca-nav__system-sub-list {
              &::before {
                content: none;
              }
            }
          }
        }

        &[data-system-type] {
          .bca-nav__system-item-list-title > a {
            @include bca-icon(system) {
              width: 16px;
              text-align: center;
            }
          }
        }

        &[data-system-type="siteconfigs"] {
          .bca-nav__system-list-item-title > a {
            @include bca-icon(siteconfigs);
          }
        }
        &[data-system-type="users"] {
          .bca-nav__system-list-item-title > a {
            @include bca-icon(users);
          }
        }
        &[data-system-type="sites"] {
          .bca-nav__system-list-item-title > a {
            @include bca-icon(sites);
          }
        }
        &[data-system-type="theme"] {
          .bca-nav__system-list-item-title > a {
            @include bca-icon(theme);
          }
        }
        &[data-system-type="plugin"] {
          .bca-nav__system-list-item-title > a {
            @include bca-icon(plugin);
          }
        }
        &[data-system-type="tools"] {
          .bca-nav__system-list-item-title > a {
            @include bca-icon(tools);
          }
        }
        &[data-sub-content-is-current="true"] {
          .bca-nav__system-list-item-title {
            background-color: $color_secondary;
            &-label {
              color:$color_lightest;
            }
          }
        }
        // 中が展開されている時
        &[data-sub-content-is-expanded="true"] {
          .bca-nav {
            &__system {
              &-sub-list {
                display: block;
                position: relative;
                top: auto;
                left: auto;
                width: auto;
                transform: none;
                box-shadow: none;
                //background: $color_background_menu_current;
                padding-top: 0;
                color: $color_text_primary;
              }
              &-list-item {
                &-title {
                  background-color: $color_background_menu_dark;
                  &-label {
                    //color:$color_lightest;
                  }
                }
              }
            }
          }
        }
      }
    }

    &-sub-list {
      display: none;
      margin: 0;
      list-style: none;
      position: absolute;
      top: 0;
      left: 100%;
      min-width: 200px;
      padding-left: 0;
      padding-top:11px;
      padding-bottom:11px;
      &-item {
        margin: 0;
        a {
          font-size: 1.3rem;
          padding: 7px 20px 5px 35px;
          color: $color_text_primary;
          display:block;
        }
        a:hover {
          font-weight: bold;
          text-decoration: none;
        }
        // 現在開いているページ
        &[data-sub-item-is-current="true"] {
          // background: $color_primary;
          background: $color_secondary;
          a {
            position: relative;
            padding-left: 35px;
            color: $color_lightest;
            font-weight: bold;
            padding-top:13px;
            padding-bottom:11px;
          }
        }
      }
    }
  }
}
