.bca-update-log {
  &__list {
    margin: 20px 0 30px;
    padding: 0;
    list-style: none;
    &-item {
      padding-bottom:9px;
      margin-bottom: 8px;
      line-height: 1.5;
      border-bottom: 1px solid $color_border;
      word-break: break-word;
      .date {
        font-size:80%;
      }
    }
  }
}
