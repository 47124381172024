.bca-app {
  display: grid;
  min-height: 100vh;
  grid-template-rows: auto 1fr auto;
  @include mq(sp) {
  	display: block;
  }
  .bca-header {

  }
  .bca-container {

  }
  .bca-footer {

  }
}
