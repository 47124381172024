.bca-main {
  &__submenu {
    &-title {
      font-size:$label_font_size_small;
      font-weight: normal;
      margin:0;
    }
    &-list {
      padding-left:20px;
      border-bottom:1px solid $color_border;
      padding-bottom: 10px;
      margin-bottom: 10px;
      margin-top:10px;
      &-item {
        float:left;
        font-size:$button_font_size_small;
        margin-right:40px;
        margin-bottom:5px;
      }
    }
  }
}
