.bca-nav-favorite {
  font-size: 1.6rem;
  // border: 0;
  border-bottom: 1px solid $color_menu_border;
  background: $color_background_menu;
  &-title {
    margin: 0;
    font-weight: bold;
    &-button {
      @include bca-icon(favorite) {
        margin: 0 .3em 0 0;
      }
      display: block!important;
      padding: 12px!important;
      vertical-align: middle;
      width:100%!important;
      margin:0;
      color: $color_menu_link!important;
      font-size: 14px!important;
      font-weight: bold;
      text-align: left!important;
    }
    [data-bca-state="open"] &-icon {
        transform: rotate(180deg);
    }
    &-icon {
      display: inline-block;
      vertical-align: middle;
      margin-right: 6px;
      float: right;
      transition: 0.1s all;
    }
  }
  &-list {
    display:none;
    margin: 0;
    list-style: none;
    padding: 0 0 4px 21px;
    &-item {
      font-size: .875em;
      display: block;
      a {
        position: relative;
        display: block;
        //padding: 4px 0px 7px 14px;
        padding: 6px 20px 5px 14px;
        //color: $color_text_secondary;
        font-size: 1.3rem;
        //@extend .bca-icon--menuitem;
        &:before {
          position: absolute;
          left: 0;
          top: $nav_item_padding * 1;
          margin-right: 0;
        }

        &:link,
        &:visited {
          // a:link のデフォルト設定を消すまで有効
          color: $color_menu_link;
        }
        &:hover {
          font-weight: bold;
          color: $color_menu_hover;
          text-decoration: none;
        }
      }
      &-label {
        display: inline-block;
        //vertical-align: middle;
        //margin-left: 4px;
      }
    }
  }
  &-functions {
    font-size: .75em;
    list-style: none;
    padding-left: #{$nav_base_padding - $nav_item_padding};
    &-add {
      @include bca-icon(add) {
        margin: 0 .3em 0 0;
      }
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;
      color: $color_text_link;
    }
    &-help {
      @include bca-icon(help) {
        margin: 0 .3em 0 0;
      }
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;
      color: $color_text_link;
      margin-left: 15px;
    }
  }
  .no-data {
    text-align: left;
  }
}
