/* bca-data-list
----------------------------------------------- */

.bca-data-list {
  margin-bottom: 15px;
  &__top {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 20px;
  }
  &__sub {
    flex: 1;
    text-align: right;

    display: inline-flex;
    flex-direction: column;
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 15px;
  }
}
