/* action
----------------------------------------------- */
.bca-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 20px;
  padding-top: 20px;
  border-top: 1px solid $color_table_border_color;
  &__main {

  }
  &__sub {
    margin-left: 30px;
    padding-left: 30px;
    border-left: 1px solid #ccc;
  }
  &__item {
    margin: 0 10px;
  }
  &__before {
    margin-right: 30px;
    padding-right: 30px;
    border-right: 1px solid #ccc;
  }

  &[data-bca-type=type2] {
    display: block;
    .bca-actions {
      &__form {
        margin-bottom: 20px;
      }
      &__adds {
        margin: 20px 0;
      }
    }
  }
}
